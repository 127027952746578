import * as React from "react"
import Layout from '../components/Layout'
import SEO from "../components/seo"
import {MetodosDePago} from '../components/MetodosDePago';
import {Wizard} from '../components/Wizard';

const MetodoPagoPage = () => {

  return (
    <Layout clsOverflow={false} typeRouteMenu={'/#'}>
      <SEO
        title=""
        description=""
        works=''
        bolImage='1'
        ogImage={`home_f.jpg`}
        twitterImage={`home_t.jpg`}
        canonicalUrl="https://www.callmatik.com/payment"
      />

      <Wizard step={2}/>

      <MetodosDePago/>

    </Layout>
  )
}

export default MetodoPagoPage;
