import { createGlobalStyle } from 'styled-components'
import {GlobalFonts} from '../../../styles/GlobalStyles';
import {GlobalColorsInputText} from '../../../styles/appColors';

export const GlobalStylesInputTextMySMaterial = createGlobalStyle`

  .MuiFormLabel-root.Mui-focused,
  .MuiInputLabel-outlined.MuiInputLabel-shrink{
    padding: 0 4px;
    background-color: ${GlobalColorsInputText.primaryBg};
    font: 16px ${GlobalFonts.fontRegular};
    color: ${GlobalColorsInputText.label};
  }
`
