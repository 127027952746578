import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import {GlobalColorsInputText} from '../../../styles/appColors';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
  cssLabel: {
    color : GlobalColorsInputText.label
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${GlobalColorsInputText.labelFocus} !important`,
    }
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '2px',
    borderRadius: '10px',
    borderColor: `${GlobalColorsInputText.labelFocus} !important`,
  },
});

const SelectMySMaterial = ({
    name,
    data,
    onChange,
    ...otherProps
  }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const { classes } = otherProps;

  const handleChange = evt => {
    console.log('handleChange', evt);
    const { value } = evt.target;
    setFieldValue(name, value);
    onChange(evt);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    variant: 'outlined',
    fullWidth: true,
    onChange: handleChange,
    InputLabelProps: {
      classes: {
        root: classes.cssLabel,
        focused: classes.cssFocused,
      },
    },
    InputProps: {
      classes: {
        root: classes.cssOutlinedInput,
        focused: classes.cssFocused,
        notchedOutline: classes.notchedOutline,
      },
    }
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <TextField {...configSelect}>
      {/*{Object.keys(data).map((item, pos) => {*/}
      {data.map((item, pos) => {
        // console.log(item);
        return (
          <MenuItem key={pos} value={item.value}>
            {/*{data[item]}*/}
            {item.label}
          </MenuItem>
        )
      })}
    </TextField>
  );
};

// export default SelectMySMaterial;
export default withStyles(styles)(SelectMySMaterial);
