import styled, { createGlobalStyle } from 'styled-components'
import {GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const StylesMetodosDePago = createGlobalStyle`

`
export const ContMetodosDePago = styled.div`
  margin-bottom: 50px;
`
export const ContForm = styled.div`
  width: 100%;
  margin-top: 30px;
`
export const ContCheckbox = styled.div`
  width: 100%;
  padding: 15px 25px;
`
export const ContProduct = styled.div`
  width: 100%;
  margin: 15px 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`
export const ContProductItemLeft = styled.div`
  width: 80%;
`
export const ContProductItemRight = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
`
export const ContServices = styled.div`
  width: 100%;
`
export const ContServicesDetail = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`
export const ContServicesDetailItem = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`
export const ContServicesDetailItemIco = styled.div`
  width: 33px;
  height: 33px;
  margin-right: 15px;
`
export const ContServicesDetailItemInfo = styled.div`
  width: 84%;
  height: auto;
  font: 18px ${GlobalFonts.fontRegular}; 
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${GlobalColors.colorText};
`
export const ContAdditionalServices = styled.div`
  width: 100%;
`
export const ContAdditionalServicesDetail = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`
export const ContAdditionalServicesDetailItem = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`
export const ContCheckboxAdditionalServices = styled.div`
  width: 100%;
  padding: 0;
`
export const ContTotal = styled.div`
  width: 100%;
`
export const ContTotalDetail = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`
export const ContTotalItem = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`
export const ContTotalNumber = styled.div`
  width: auto;
  height: 36px;
  margin-right: 15px;
  font: 28px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  color: ${GlobalColors.colorPrimary};
  
  &>span{
    font: 15px ${GlobalFonts.fontRegular};
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    color: ${GlobalColors.colorPrimary};
  }
`
export const ContTotalTextIVA = styled.div`
  position: relative;
  height: 36px;
  bottom: -10px;
  font: 15px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  color: ${GlobalColors.colorTextDiscreet};
`
export const ContMessage = styled.div`
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const ContMessageDetail = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  @media(min-width: 300px) and (max-width: 1023px){
    width: 100%;
    padding: 0 15px;
  }
  @media(min-width: 1024px){
    width: 530px;
  }
`
export const ContMessageDetailInfo = styled.div`
  font: 36px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  text-align: center;
  color: ${GlobalColors.colorBtnTextPrimary};
`
export const ContMessageDetailInfoMinor = styled.div`
  font: 18px ${GlobalFonts.fontPrimary};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: ${GlobalColors.colorBtnTextPrimary};

  @media(min-width: 300px) and (max-width: 1023px){
    width: 100%;
  }
  @media(min-width: 1024px){
    width: 415px;
  }
`
export const ContLoader = styled.div`
  width: 75px;
  height: 75px;
`
export const ContClick = styled(AniLink)`
  width: 75px;
  height: 75px;
`
export const ContBtnShowPlanMobile = styled.button`
  position: absolute;
  width: 33px;
  height: 33px;
  top: 15px;
  right: 10px;
`
export const ContField = styled.div`
  position: relative;
  width: 100%;
  height: 78px;
  margin-bottom: 0px;
`
export const ErrorMessage = styled.h1`
  color: ${GlobalColors.colorDanger};
`
export const ContIcon = styled.div`
  width: 85%;
  margin-right: 15px;
`

export const ContIconCard = styled.div`
  display: flex;
  justify-content: center;
  width: 85%;
  text-align: center;
`