import React, {useEffect, useState} from 'react';
import {TransitionState} from 'gatsby-plugin-transition-link';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import {
  Column, Columns, Container,
  Modal,
  ModalBackground,
  ModalContent,
  ModalClose,
} from 'bloomer';
import imgPayment from './conekta-powered-by-20px.svg';
import imgCardPayment from './card-brands-24px.svg';
import {
  ContMetodosDePago,
  ContForm,
  ContProduct,
  ContProductItemLeft,
  ContProductItemRight,
  ContServices,
  ContServicesDetail,
  ContServicesDetailItem,
  ContServicesDetailItemIco,
  ContServicesDetailItemInfo,
  // ContAdditionalServices,
  // ContAdditionalServicesDetail,
  // ContAdditionalServicesDetailItem,
  // ContCheckboxAdditionalServices,
  ContTotal,
  ContTotalDetail,
  ContTotalItem,
  ContTotalNumber,
  ContTotalTextIVA,
  ContMessage,
  ContMessageDetail,
  ContMessageDetailInfo,
  // ContMessageDetailInfoMinor,
  ContLoader,
  ContBtnShowPlanMobile, ContField,
  // ContModal,
  ErrorMessage,
  ContIcon,
  ContIconCard
} from './styles';
import {
  ContColumnInner,
  ContColumnsInLine,
  ContImportantElement,
  Linea,
  PieTitleSectionMini,
  TitlePlanSummary,
  Separador,
  TitleSectionMini,
  TitleSectionSecondary,
  NamePlanSummary,
  GlobalColors,
  BtnPrimary,
  TitleSectionMiniMobile,
  PieTitleSectionMiniMobile,
  TitleSection,
  
} from '../../styles/GlobalStyles';
import useWindowDimensions from '../../helpers/useWindowDimensions';
import IconEdit from '../../images/icon-edit.svg';
import IconPhone from '../../images/ico-phone.svg';
import IconBell from '../../images/ico-bell.svg';
import IconPlus from '../../images/ico-plus.svg';
import ImgLoader from '../../images/loader.gif';
import IconDown from '../../images/icon-down.svg';
import IconUp from '../../images/icon-up.svg';
import axios from 'axios';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import InputTextMySMaterial from '../ui/InputTextMySMaterial';
import SelectMySMaterial from '../ui/SelectMySMaterial';
import CheckboxMySMaterial from '../ui/CheckboxMySMaterial';
// ContIcon

export const MetodosDePago = () => {
  const [showMessageCP, setShowMessageCP] = useState(false);
  const [showMessageCPInvoice, setShowMessageCPInvoice] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showMessageRFC, setShowMessageRFC] = useState(false);
  const [message, setMessage] = useState('');
  const [showMessageError, setShowMessageError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [stateAux, setStateAux] = useState();
  const [disabledPay, setDisabledPay] = useState(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [iframeLoad, setiframeLoadLoad] = useState(false);
  const [goTo, setGoTo] = useState();
  const [token, setToken] = useState();
  const [paymentMethod, setPaymentMethod] = useState({
    name: '',
    email: '',
    phone: '',
    street: '',
    externalnumber: '',
    internalnumber: '',
    cp: '',
    municipality: '',
    suburb: '',
    state: '',
    requireinvoice: false,
    typeperson: '',
    rfc: '',
    businessname: '',
    samebillingaddress: false,
    streetinvoice: '',
    externalnumberinvoice: '',
    internalnumberinvoice: '',
    cpinvoice: '',
    suburbinvoice: '',
    municipalityinvoice: '',
    stateinvoice: '',
    services: [],
    promotionalCode: '',
    promotionalCodeColaborador: '',
    test: '',
    methodPay: 'TARJETA'
  });
  const [paymentMethodAux, setPaymentMethodAux] = useState({
    name: '',
    email: '',
    phone:'',
    street: '',
    externalnumber: '',
    internalnumber: '',
    cp: '',
    municipality: '',
    state: '',
    methodPay: 'TARJETA'
  });
  const [parentValidateForm, setParentValidateForm] = useState({
    cardname: false,
    cardnumber: false,
    cardmonth: false,
    cardyear: false,
    cardcvv: false,
    street: false,
    externalnumber: false,
    cp: false,
    municipality: false,
    state: false,
  });
  const [discountPromo, setDiscountPromo] = useState({
    discountTotal: 0,
    discountPorcentaje: 0,
    discountQty: 0,
    showDisccount: false,
    promocod_colaborador:'',
    promocod_customer:'',
  });
  const [invoice, setInvoice] = useState({
    requireinvoice: false,
    requireinvoiceDisable: false,
    samebillingaddress: false,
    typeperson: '',
    rfc: '',
    businessname: '',
    street: '',
    externalnumber: '',
    internalnumber: '',
    cp: '',
    municipality: '',
    state: '',
    suburb: '',
    regimen: ''
  });
  const [plan, setPlan] = useState({
    plan: '',
    price: 0,
    calls: 0,
    notifications: 0,
    cost: 0,
    services: [],
  });
 

  const [save, setSave] = useState(0);
  const [total, setTotal] = useState(0);
  const [promotionalCode, setPromotionalCode] = useState('');
  const [promotionalCodeColaborador, setPromotionalCodeColaborador] = useState('');
  const [screenBtnShowPlan, setScreenBtnShowPlan] = useState();
  const [showPlan, setShowPlan] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [charge, setCharge] = useState({});
  const [tokenConekta, setTokenConekta] = useState({});
  const {height, width} = useWindowDimensions();
  const API_KEY = `${process.env.API_KEY}`;

  const CONEKTA_PUBLIC_KEY = `${process.env.CONEKTA_PUBLIC_KEY}`;
  let URL_TOKEN = `${process.env.GATSBY_API_URL}:${process.env.PORT}/token`;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
    console.log(paymentMethod, 'paymentMethod openModal')
    console.log(discountPromo, 'discountPromo')
    iframeConekta(tokenConekta)
  }

  function closeModal() {
    setIsOpen(false);
  }


    
  let URL_CLIENT = `${process.env.GATSBY_API_URL}:${process.env.PORT}/clients`;
  let URL_ZIPCODE = `${process.env.GATSBY_API_URL}:${process.env.PORT}/zipcodes`;
  let URL_PROMO = `${process.env.GATSBY_API_URL}:${process.env.PORT}/validate`;
  let dataResponse;
  let dataPersonal;
  let dataInvoice;
  let dataPlan;
  if ( process.env.NODE_ENV === 'production' ) {
    URL_CLIENT = `${process.env.GATSBY_API_URL}/clients`;
    URL_TOKEN = `${process.env.GATSBY_API_URL}/token`;
    URL_ZIPCODE = `${process.env.GATSBY_API_URL}/zipcodes`;
    URL_PROMO = `${process.env.GATSBY_API_URL}/validate`;
  }

  function aplicarPromo(){
    let data = {
      discount: paymentMethod.promotionalCode,
      discountColaborador: paymentMethod.promotionalCodeColaborador,
    }
    console.log(data, 'data');
    axios({
      method: 'post',
      url: URL_PROMO,
      data: data,
      headers: {
        'x-api-key': API_KEY,
        'Content-Type': 'application/json',
      },
    }).then(function (response) {
      console.log(response, 'response');
      console.log(response.data.data.code, 'responsedata.data.code');
      if(response.data.data.code !== 200){
        setShowMessage(true);
        setShowMessageError(true);
        setMessageError(response.data.data.error);
        setDiscountPromo({
          ...discountPromo,
          discountTotal: 0,
          showDisccount: false,
          discountPorcentaje: 0,
          discountQty: 0,
          promocod_colaborador:'',
          promocod_customer:'',
        })
      }else{
        console.log(plan.price, response.data.data.result.totalDiscount);
        let priceBody = (typeof plan.price == 'number')? plan.price : plan.price.replace(',','');
        console.log(priceBody, 'priceBody')
        const unit_price = (typeof priceBody == 'string')?Number(priceBody): plan.price;
        console.log(unit_price, 'unit price')

        let priceDis = ((unit_price *  response.data.data.result.totalDiscount)/100);
        let tot = Math.round(unit_price - priceDis)
        setDiscountPromo({
          ...discountPromo,
          discountTotal: tot,
          discountQty: Math.round(priceDis),
          showDisccount: true,
          discountPorcentaje: response.data.data.result.totalDiscount,
          promocod_colaborador: response.data.data.result.promocod_colaborador,
          promocod_customer: response.data.data.result.promocod_customer,
        })
        setPaymentMethod({
          ...paymentMethod,
          price: tot
        });

      }
    })
    .catch(function (error) {
      console.log(error, 'error');
    });
  }
  const dataMethodPay = [
    {
      label: 'Tarjeta de crédito o débito',
      value: 'TARJETA'
    }
  ]

  const dataMonth = [];
  for ( let x = 1; x <= 12; x++ ) {
    dataMonth.push({
      label: x.toString() < 10 ? `0${x.toString()}` : x.toString(),
      value: x.toString() < 10 ? `0${x.toString()}` : x.toString(),
    });
  }
  const dataYear = [];
  for ( let x = 21; x <= 30; x++ ) {
    dataYear.push({
      label: x.toString(), value: x.toString(),
    });
  }

  const dataTypePerson = [
    {label: 'Moral', value: 'Moral'},
    {label: 'Física', value: 'Física'},
  ];
  const regimenFisica = [
    { type: "Física", value: "605", label: "Sueldos y Salarios e Ingresos Asimilados a Salarios"},
    { type: "Física", value: "606", label: "Arrendamiento"},
    { type: "Física", value: "607", label: "Régimen de Enajenación o Adquisición de Bienes"},
    { type: "Física", value: "608", label: "Demás ingresos" },
    { type: "Física", value: "610", label:"Residentes en el Extranjero sin Establecimiento Permanente en México"},
    { type: "Física", value: "611", label: "Ingresos por Dividendos (socios y accionistas)" },
    { type: "Física", value: "612", label: "Personas Físicas con Actividades Empresariales y Profesionales"},
    { type: "Física", value: "614", label: "Ingresos por intereses" },
    { type: "Física", value: "615", label: "Régimen de los ingresos por obtención de premios" },
    { type: "Física", value: "616", label: "Sin obligaciones fiscales" },
    { type: "Física", value: "621", label: "Incorporación Fiscal" },
    { type: "Física", value: "625", label: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas"},
    { type: "Física", value: "626", label: "Régimen Simplificado de Confianza" },
  ];

  const regimenMoral = [
    { type: "Moral",  value: "601",	label: "General de Ley Personas Morales"},
    { type: "Moral",  value: "603",	label: "Personas Morales con Fines no Lucrativos"},
    { type: "Moral",  value: "610",	label: "Residentes en el Extranjero sin Establecimiento Permanente en México"},
    { type: "Moral",  value: "620",	label: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos"},
    { type: "Moral",  value: "622",	label: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras"},
    { type: "Moral",  value: "623",	label: "Opcional para Grupos de Sociedades"},
    { type: "Moral",  value: "624",	label: "Coordinados"},
    { type: "Moral",  value: "626",	label: "Régimen Simplificado de Confianza"},
  ]

  const dataRegimenFiscal = ()=>{ 
    if(paymentMethod.typeperson == 'Física'){
      return regimenFisica;
    };
    if(paymentMethod.typeperson == 'Moral'){
      return regimenMoral;
    };
    
    return []
  };

  const validationSchema = Yup
    .object()
    .shape({
      methodPay: Yup.string().required('Selecciona el Método de pago.'),
      street: Yup.string().required('Escribe la calle'),
      externalnumber: Yup.string().required('Escribe el Nª Exterior'),
      internalnumber: Yup.string(),
      cp: Yup.number().required('Escribe el Código Postal'),
      municipality: Yup.string().required('Escribe el Municipio'),
      state: Yup.string().required('Escribe el estado'),
      requireinvoice: Yup.boolean(),
      typeperson: Yup.string(),
      rfc: Yup.string(),
      businessname: Yup.string(),
      samebillingaddress: false,
      streetinvoice: Yup.string(),
      externalnumberinvoice: Yup.string(),
      internalnumberinvoice: Yup.string(),
      cpinvoice: Yup.string(),
      municipalityinvoice: Yup.string(),
      stateinvoice: Yup.string(),
      services: Yup.array(),
      promotionalCode: Yup.string(),
      promotionalCodeColaborador: Yup.string(),
    })
    .when((values, schema) => {
      if (values.requireinvoice === true) {
        return schema.shape({
          typeperson: Yup.string().required('Selecciona el tipo de persona'),
          rfc: Yup.string().required('Escribe el RFC'),
          businessname: Yup.string().required('Escribe el nombre de la empresa'),
          streetinvoice: Yup.string().required('Escribe el nombre de la calle'),
          externalnumberinvoice: Yup.string().required('Escribe el Nª Exterior'),
          cpinvoice: Yup.string().required('Escribe el Código Postal'),
          municipalityinvoice: Yup.string().required('Escribe el Municipio'),
          stateinvoice: Yup.string().required('Escribe el estado'),
        });
      }
    });
  const initialValues = {
    name: paymentMethod.name,
    email: paymentMethod.email,
    phone: paymentMethod.phone,
    street: paymentMethod.street,
    externalnumber: paymentMethod.externalnumber,
    internalnumber: paymentMethod.internalnumber,
    cp: paymentMethod.cp,
    municipality: paymentMethod.municipality,
    suburb: paymentMethod.suburb,
    state: paymentMethod.state,
    methodPay: paymentMethod.methodPay,
    requireinvoice: false,
    typeperson: invoice.typeperson,
    rfc: invoice.rfc,
    businessname: invoice.businessname,
    samebillingaddress: false,
    streetinvoice: invoice.street,
    externalnumberinvoice: invoice.externalnumber,
    internalnumberinvoice: invoice.internalnumber,
    cpinvoice: invoice.cp,
    municipalityinvoice: invoice.municipality,
    suburbinvoice: paymentMethod.suburbinvoice,
    stateinvoice: invoice.state,
    services: plan.services,
    promotionalCode: '',
    promotionalCodeColaborador: '',
  };

  const onChangeMethodPay = val => {
    setPaymentMethod({
      ...paymentMethod,
      methodPay: val,
    });
  };

  const onChangeCardStreet = val => {
    setPaymentMethod({
      ...paymentMethod,
      street: val,
    });
  };
  const onChangeCardExternalNumber = val => {
    setPaymentMethod({
      ...paymentMethod,
      externalnumber: val,
    });
  };
  const onChangeCardInternalNumber = val => {
    setPaymentMethod({
      ...paymentMethod,
      internalnumber: val,
    });
  };
  const onChangeCP = val => {
    setPaymentMethod({
      ...paymentMethod,
      cp: val,
    });
    searchZipCode(val);
  };
  const onChangeMunicipality = val => {
    setPaymentMethod({
      ...paymentMethod,
      municipality: val,
    });
  };
  const onChangeState = val => {
    setPaymentMethod({
      ...paymentMethod,
      state: val,
    });
  };
  const onRequireInvoice = (val) => {
    // console.log('onRequireInvoice =>', val);
    // console.log('onRequireInvoice => invoice =>', invoice);
    setInvoice({
      ...invoice,
      // requireinvoice: !invoice.requireinvoice,
      requireinvoice: val,
    });
  };
  const onSameBillingAddress = (val) => {
    console.log('onSameBillingAddress => val =>', val);
    // console.log('onSameBillingAddress => val.samebillingaddress =>', val.samebillingaddress);

    // const SAMEBILLINGADDRESS = val;

    // setInvoice({
    //   ...invoice,
    //   samebillingaddress: SAMEBILLINGADDRESS,
    //   street:             SAMEBILLINGADDRESS ? paymentMethod.street : '',
    //   externalnumber:     SAMEBILLINGADDRESS ? paymentMethod.externalnumber : '',
    //   internalnumber:     SAMEBILLINGADDRESS ? paymentMethod.internalnumber : '',
    //   cp:                 SAMEBILLINGADDRESS ? paymentMethod.cp : '',
    //   municipality:       SAMEBILLINGADDRESS ? paymentMethod.municipality : '',
    //   state:              SAMEBILLINGADDRESS ? paymentMethod.state : '',
    // });

    /*val.samebillingaddress    = SAMEBILLINGADDRESS;
    val.streetinvoice         = SAMEBILLINGADDRESS ? paymentMethod.street         : '';
    val.externalnumberinvoice = SAMEBILLINGADDRESS ? paymentMethod.externalnumber : '';
    val.internalnumberinvoice = SAMEBILLINGADDRESS ? paymentMethod.internalnumber : '';
    val.cpinvoice             = SAMEBILLINGADDRESS ? paymentMethod.cp             : '';
    val.municipalityinvoice   = SAMEBILLINGADDRESS ? paymentMethod.municipality   : '';
    val.stateinvoice          = SAMEBILLINGADDRESS ? paymentMethod.state          : '';*/
  };
  const onChangeTypePerson = val => {
    setInvoice({
      ...invoice,
      typeperson: val,
    });
    setPaymentMethod({
      ...paymentMethod,
      typeperson: val
    })
  };
  const onChangeRegimen = val => {
    setInvoice({
      ...invoice,
      regimen: val,
    });
  };
  const onChangeRFC = val => {
    let rfc = val.trim().toUpperCase();
    if (validateRFC(rfc)) {
      setShowMessageRFC(false);
    } else {
      setShowMessageRFC(true);
    }
    setInvoice({
      ...invoice,
      rfc: rfc,
    });
    setPaymentMethod({
      ...paymentMethod,
      rfc: rfc,
    });
  };
  const onChangeBussinesName = val => {
    setInvoice({
      ...invoice,
      businessname: val,
    });
  };
  const onChangeStreetInvoice = val => {
    setInvoice({
      ...invoice,
      street: val,
    });
  };
  const onChangeNumExtInvoice = val => {
    setInvoice({
      ...invoice,
      externalnumber: val,
    });
  };
  const onChangeNumIntInvoice = val => {
    setInvoice({
      ...invoice,
      internalnumber: val,
    });
  };
  const onChangeCPInvoice = val => {
    console.log(val, 'val')
    searchZipCodeInvoice(val);
    setInvoice({
      ...invoice,
      cp: val,
    });
    // triggerSaveLocalStorage();
  };
  const onChangeMunicipalityInvoice = val => {
    setInvoice({
      ...invoice,
      municipality: val,
    });
  };
  const onChangeStateInvoice = val => {
    setInvoice({
      ...invoice,
      state: val,
    });
  };

  const onCheckServices = index => {
    setSave(save + 1);
    let arStates = plan.services.slice();
    arStates[index].value = !arStates[index].value;
    // setStService(arStates);
    setPlan({
      ...plan,
      services: arStates,
    });
  };
  const onChangePromotionalCode = val => {
    // console.log('onChangePromotionalCode => ', val);
    setPromotionalCode(val);
    setPaymentMethod({
      ...paymentMethod,
      promotionalCode: val,
    });
  };

  const onChangePromotionalCodeColaborador = val => {
    setPromotionalCodeColaborador(val);
    setPaymentMethod({
      ...paymentMethod,
      promotionalCodeColaborador: val,
    });
  }

  const MessageComp = () => {
    return (
      <ContMessageDetail>
        {message === ''
          ? <ContMessageDetail>
            <ContMessageDetailInfo>
              Estamos validando tus datos, tomará algunos segundos.
            </ContMessageDetailInfo>
            <ContLoader><img src={ImgLoader} alt={''}/></ContLoader>
            {/* <ContMessageDetailInfoMinor>*Te realizaremos un cargo de 3 peso para verificar que tu cuenta es
              válida.</ContMessageDetailInfoMinor> */}
          </ContMessageDetail>
          : <ContMessageDetail>
            <ContMessageDetailInfo>Hubo un error</ContMessageDetailInfo>
            <ContMessageDetailInfo>{message}</ContMessageDetailInfo>
          </ContMessageDetail>
        }
      </ContMessageDetail>
    );
  };
  const MessageCompError = () => {
    return (
      <ContMessageDetail>
        <ContMessageDetailInfo>Hubo un error</ContMessageDetailInfo>
        <ContMessageDetailInfo>{messageError}</ContMessageDetailInfo>
      </ContMessageDetail>
    );
  };
  const onValidatePersonalData = (values) => {
    console.log(values, 'values')
    if ( 
      values.street         !== '' &&
      values.externalnumber !== '' &&
      values.cp             !== '' &&
      values.municipality   !== '' &&
      values.state          !== '' &&
      values.methodPay   !== ''
    ) {
      setInvoice({
        ...invoice,
        requireinvoiceDisable: !invoice.requireinvoiceDisable,
      });
    } else {
      setInvoice({
        ...invoice,
        requireinvoiceDisable: false,
      });
    }
  };
  const onValidatePersonalDataForInvoice = (values, paymentMethod) => {
    console.log(values, 'values')
    console.log(paymentMethod, 'values')
    if ( 
      values.street         !== '' &&
      values.externalnumber !== '' &&
      values.cp             !== '' &&
      // values.municipality   !== '' &&
      // values.state          !== '' &&
      values.methodPay   !== ''
    ) {
      setInvoice({
        ...invoice,
        requireinvoice: !invoice.requireinvoice,
      });
    } else {
      setInvoice({
        ...invoice,
        requireinvoice: false,
      });
    }
  };
  const onValidateData = () => {
    // console.log('onValidateData => paymentMethod =>', paymentMethod);
    // console.log('paymentMethod.cardname =>', paymentMethod.cardname);
    if ( 
      paymentMethod.street !== '' &&
      paymentMethod.externalnumber !== '' &&
      paymentMethod.cp !== '' &&
      paymentMethod.municipality !== '' &&
      paymentMethod.state !== '' &&
      paymentMethod.methodPay !== ''
    ) {
      // console.log('paymentMethod.cardname = Cadena con datos');
      setDisabledPay(false);
      onValidateInvoice();
    } else {
      // console.log('paymentMethod.cardname = Cadena vacía');
      setDisabledPay(true);
    }
  };
  const onValidateInvoice = () => {
    // console.log('onValidateInvoice => invoice.requireinvoice =>', invoice.requireinvoice);
    if ( invoice.requireinvoice ) {
      if ( invoice.typeperson !== '' &&
        invoice.rfc !== '' &&
        invoice.businessname !== '' &&
        invoice.street !== '' &&
        invoice.externalnumber !== '' &&
        invoice.cp !== '' &&
        invoice.municipality !== '' &&
        invoice.state !== ''
      ) {
        setDisabledPay(false);
      } else {
        setDisabledPay(true);
      }
    }
  };

  const validateRFC = (rfc, aceptarGenerico = true) => {
      
      const re       = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
      var   validado = rfc.match(re);
  
      if (!validado)  //Coincide con el formato general del regex?
          return false;
  
      //Separar el dígito verificador del resto del RFC
      const digitoVerificador = validado.pop(),
            rfcSinDigito      = validado.slice(1).join(''),
            len               = rfcSinDigito.length,
  
      //Obtener el digito esperado
            diccionario       = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
            indice            = len + 1;
      var   suma,
            digitoEsperado;
  
      if (len == 12) suma = 0
      else suma = 481; //Ajuste para persona moral
  
      for(var i=0; i<len; i++)
          suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
      digitoEsperado = 11 - suma % 11;
      if (digitoEsperado == 11) digitoEsperado = 0;
      else if (digitoEsperado == 10) digitoEsperado = "A";
  
      //El dígito verificador coincide con el esperado?
      // o es un RFC Genérico (ventas a público general)?
      if ((digitoVerificador != digitoEsperado)
       && (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000")){
        setShowMessageRFC(true);
         return false;
       }
      else if (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000"){

        setShowMessageRFC(true);
        return false;
      }
      setShowMessageRFC(false);
      return rfcSinDigito + digitoVerificador;
  
  };
  const onSendPay = (data) => {
    console.log(data);
    setShowMessage(true);
    axios({
      method: 'post',
      url: URL_CLIENT,
      data: data,
      headers: {
        'x-api-key': API_KEY,
        'Content-Type': 'application/json',
      },
    }).then(function (response) {
      console.log('axios => response =>', response);

      let status = response.data.data.success;
      console.log('axios => response => status =>', status);
      if ( status === true ) {
        setRedirect(response.data.data.redirect);
        setTimeout(function () {
          document.getElementById('goto-welcome').click();
        }, 3000);
      } else {
        // cambiar el mensaje
        console.log(response.data.data.error, 'response.data.data.error');
        setMessage(response.data.data.error);
        setIsOpen(false);
        setiframeLoadLoad(true);
        getToken()
        let divContent = document.getElementById('conektaIframeContainer')
        //document.getElementsByClassName('IframeTokenizer').style.background = "#FFF";
        divContent.style.background = "#FFF!important";
        // document.getElementsByClassName('IframeTokenizer').style.padding = "10px";
        while (divContent.hasChildNodes()) {
          divContent.removeChild(divContent.firstChild);
        }
      }
    })
    .catch(function (error) {
      console.log(error);
      setIsOpen(false);
      setiframeLoadLoad(true);
      getToken()
      let divContent = document.getElementById('conektaIframeContainer')
      divContent.style.background = "#FFF!important";
      // document.getElementsByClassName('IframeTokenizer').style.padding = "10px";
      while (divContent.hasChildNodes()) {
        divContent.removeChild(divContent.firstChild);
      }
      let message = 'Ocurrió un error';
      if ( error.hasOwnProperty('message') ) {
        message = error.message.toString();
        setMessageError(error.message.toString());
      }

      setMessageError(message);

      setShowMessageError(true);
      console.log(showMessageError)

      console.log('message =>', message);
    });
  }
  const onCheckout = (val) => {
    // console.log('onCheckout');
    // console.log('onCheckout => disabledPay =>', disabledPay);
    console.log(val, 'val');
    console.log(paymentMethod, 'paymentMethod');
    console.log(tokenConekta, 'tokenConekta');

    setShowMessage(true);

    const data = {
      name:                 paymentMethod.name,
      last_name:            paymentMethod.last_name,
      second_lastname:      paymentMethod.second_lastname,
      email:                paymentMethod.email,
      phone:                paymentMethod.phone,
      plan:                 plan.plan,
      price:                plan.price,
      diary:                'none',
      /*additional_service_1: plan.services[0].value ? '1' : '0',
      additional_service_2: plan.services[1].value ? '1' : '0',*/
      additional_service_1: '0',
      additional_service_2: '0',
      discount:             promotionalCode || '',
      discountColaborador:  promotionalCodeColaborador || '',
      card_token:           '',
      has_invoice:          val.requireinvoice ? '1' : '0',
      type_of_person:       val.typeperson,
      rfc:                  val.rfc,
      company_name:         val.businessname,
      zip_code:             val.cp,
      street:               val.street,
      outdoor_number:       val.externalnumber,
      interior_number:      val.internalnumber,
      suburb:               val.municipality,
      municipality:         val.municipality,
      state:                val.state,
      methodPay:            val.methodPay,
      token:                tokenConekta
    };
    console.log(data, 'data post');

    axios({
      method: 'post',
      url: URL_CLIENT,
      data: data,
      headers: {
        'x-api-key': API_KEY,
        'Content-Type': 'application/json',
      },
    }).then(function (response) {
      console.log('axios => response =>', response);

      let status = response.data.data.success;
      console.log('axios => response => status =>', status);
      if ( status === true ) {
        setRedirect(response.data.data.redirect);
        setCharge(response.data.data.charge);
        //setShowMessage(false);
        setTimeout(function () {
          document.getElementById('goto-welcome').click();
        }, 3000);
      } else {
        // cambiar el mensaje
        setMessage(response.data.data.error);
      }
    })
    .catch(function (error) {

      let message = 'Ocurrió un error, intente más tarde';
      if ( error.hasOwnProperty('message') ) {
        message = error.message.toString();
        setMessageError(error.message.toString());
      }

      setShowMessageError(true);

      console.log('message =>', message);
      console.log(error);
      console.log(JSON.stringify(error));
    });
  };
  const handleCloseMessage = () => {
    setShowMessage(false);
    setShowMessageError(false);
    setMessageError('');
  };

  const onShowPlan = () => {
    // console.log('onShowPlan');
    setShowPlan(!showPlan);
  };

  const triggerSaveLocalStorage = () => {
    setSave(save + 1);
  };
  const onSaveStorage = (data) => {
    if ( typeof (Storage) !== 'undefined' ) {
      localStorage.setItem('callmatik_response', JSON.stringify(data));
      // console.log('data =>', data);
    } else {
      // LocalStorage no soportado en este navegador
    }
  };
  const onSaveStoragePersonalData = () => {
    if ( typeof (Storage) !== 'undefined' ) {
      localStorage.setItem('callmatik_personal_data', JSON.stringify(paymentMethodAux));
      localStorage.setItem('callmatik_invoice', JSON.stringify(invoice));
      localStorage.setItem('callmatik_plan', JSON.stringify(plan));
    } else {
      // LocalStorage no soportado en este navegador
    }
  };
  const onConfigSaveStoragePersonalData = () => {
    setPaymentMethodAux({
      name:           paymentMethod.name,
      email:          paymentMethod.email,
      phone:          paymentMethod.phone,
      street:         paymentMethod.street,
      externalnumber: paymentMethod.externalnumber,
      internalnumber: paymentMethod.internalnumber,
      cp:             paymentMethod.cp,
      municipality:   paymentMethod.municipality,
      state:          paymentMethod.state,
      methodPay:      paymentMethod.methodPay,
      discount:       paymentMethod.promotionalCode,
      discountColaborador: paymentMethod.promotionalCodeColaborador
    });
    return 1;
  };
  const onGetStoragePersonalData = () => {
    if ( typeof (Storage) !== 'undefined' ) {
      dataPersonal = localStorage.getItem('callmatik_personal_data');
      dataPersonal = JSON.parse(dataPersonal);
      dataInvoice = localStorage.getItem('callmatik_invoice');
      dataInvoice = JSON.parse(dataInvoice);
      dataPlan = localStorage.getItem('callmatik_plan');
      dataPlan = JSON.parse(dataPlan);
      setToken(localStorage.getItem('callmatik_client_token'));

      
      console.log('dataPersonal =>', dataPersonal);
      console.log('dataInvoice =>', dataInvoice);

      if ( dataPersonal ) {
        setPaymentMethod({
          ...paymentMethod,
          name: dataPersonal.name,
          last_name: dataPersonal.last_name,
          second_lastname: dataPersonal.second_lastname,
          email: dataPersonal.email,
          phone: dataPersonal.phone,
          cp: dataPersonal.cp,
          externalnumber: dataPersonal.externalnumber,
          internalnumber: dataPersonal.internalnumber,
          municipality: dataPersonal.municipality,
          state: dataPersonal.state,
          street: dataPersonal.street,
          discount: dataPersonal.discount,
          discountColaborador: dataPersonal.discountColaborador,
        });
      }

      if ( dataInvoice ) {
        setInvoice({
          ...invoice,
          // requireinvoice:     dataInvoice.requireinvoice,
          requireinvoice:     false,
          samebillingaddress: dataInvoice.samebillingaddress,
          typeperson:         dataInvoice.typeperson,
          rfc:                dataInvoice.rfc,
          businessname:       dataInvoice.businessname,
          street:             dataInvoice.street,
          externalnumber:     dataInvoice.externalnumber,
          internalnumber:     dataInvoice.internalnumber,
          cp:                 dataInvoice.cp,
          municipality:       dataInvoice.municipality,
          state:              dataInvoice.state,
          regimen:            dataInvoice.regimen
        });
      }

      if ( dataPlan ) {
        setPlan({
          ...plan,
          calls: dataPlan.calls,
          cost: dataPlan.cost,
          notifications: dataPlan.notifications,
          plan: dataPlan.plan,
          price: dataPlan.price,
          title: dataPlan.title,
          services: dataPlan.services,
        });
      }

      setFirstLoad(true);
    } else {
      // LocalStorage no soportado en este navegador
    }
  };
  const onGetStorage = () => {
    if ( typeof (Storage) !== 'undefined' ) {
      dataResponse = localStorage.getItem('callmatik_response');
      dataResponse = JSON.parse(dataResponse);
      // console.log('dataResponse =>', dataResponse);
      // console.log('paymentMethod.name =>', paymentMethod.name);
      // console.log('dataResponse.price =>', dataResponse.price);
      // console.log('plan.price =>', plan.price);

      if ( paymentMethod.name === '' ||
        paymentMethod.name === undefined &&
        dataResponse?.name !== ''
      ) {
        // console.log('01 NAME =>', dataResponse.name);
        setPaymentMethod({
          ...paymentMethod,
          name: dataResponse?.name,
        });
      }
      if ( paymentMethod.last_name === '' ||
        paymentMethod.last_name === undefined &&
        dataResponse?.last_name !== ''
      ) {
        // console.log('02 LAST NAME =>', dataResponse.last_name);
        setPaymentMethod({
          ...paymentMethod,
          last_name: dataResponse?.last_name,
        });
      }
      if ( paymentMethod.second_lastname === '' ||
        paymentMethod.second_lastname === undefined &&
        dataResponse?.second_lastname !== ''
      ) {
        // console.log('03 SECOND NAME =>', dataResponse.second_lastname);
        setPaymentMethod({
          ...paymentMethod,
          second_lastname: dataResponse?.second_lastname,
        });
      }
      if ( paymentMethod.email === '' ||
        paymentMethod.email === undefined &&
        dataResponse?.email !== ''
      ) {
        // console.log('04 EMAIL =>', dataResponse.email);
        setPaymentMethod({
          ...paymentMethod,
          email: dataResponse?.email,
        });
      }
      if ( paymentMethod.phone === '' ||
        paymentMethod.phone === undefined &&
        dataResponse?.phone !== ''
      ) {
        // console.log('05 PHONE =>', dataResponse.phone);
        setPaymentMethod({
          ...paymentMethod,
          phone: dataResponse?.phone,
        });
      }
  
      if ( plan.plan === '' ||
        plan.plan === undefined &&
        dataResponse?.plan !== ''
      ) {
        // console.log('06 PLAN =>', dataResponse.plan);
        setPlan({
          ...plan,
          plan: dataResponse?.plan,
        });
      }
      if ( plan.title === '' ||
        plan.title === undefined &&
        dataResponse?.title !== ''
      ) {
        // console.log('07 TITLE =>', dataResponse.title);
        setPlan({
          ...plan,
          title: dataResponse?.title,
        });
      }
      if ( plan.price === '' ||
        plan.price === 0 ||
        plan.price === undefined &&
        dataResponse?.price !== ''
      ) {
        // console.log('08 PRICE =>', dataResponse.price);
        setPlan({
          ...plan,
          price: dataResponse?.price,
        });
        setTotal(dataResponse?.price);
      }
      // console.log('09 SERVICES 01 =>', dataResponse.services);
      // console.log('09 plan.services 01 =>', plan.services);
      // console.log('09 plan.services =>', plan.services);
      // console.log('09 plan.services.length 01 =>', plan.services.length);
      if ( plan.services.length === 0 ) {
        // console.log('09 SERVICES 02 =>', dataResponse.services);
        setPlan({
          ...plan,
          services: dataResponse?.services,
        });
      }
    } else {
      // LocalStorage no soportado en este navegador
    }
  };
  const validate = (values) => {
    const errors = {};
    // console.log('values =>', values);

    // if (values.requireinvoice || !values.requireinvoice) {
    //   console.log('values.requireinvoice =>', values.requireinvoice);
    //   onRequireInvoice(values.requireinvoice);
    // }

    return errors
  }
  const onSubmit = (values) => {
    // alert(JSON.stringify(values, null, 2));
    console.log('onSubmit => values =>', values);
    console.log('tokenConekta', tokenConekta)
    onCheckout(values);
  };

  const searchZipCode = (cp) => {
    if(cp.length >= 4 ){
      axios({
        method: 'post',
        url: URL_ZIPCODE,
        data: {code: cp},
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'application/json',
        },
      }).then(function (response) {
        if(response.data.data.length > 0){
          setShowMessageCP(false);
          if(response.data.data.length === 1){
            setPaymentMethod({
              ...paymentMethod,
              cp: cp,
              municipality: response.data.data[0].mnpio,
              state: response.data.data[0].estado,
              suburb: response.data.data[0].asenta
            });
          }else{
            setPaymentMethod({
              ...paymentMethod,
              cp: cp,
              municipality: response.data.data[0].mnpio,
              state: response.data.data[0].estado,
              suburb: response.data.data[0].asenta
            });
          }
        }else{
          setShowMessageCP(true);
          setPaymentMethod({
            ...paymentMethod,
            municipality: '',
            state: ''
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }
  const searchZipCodeInvoice = (cp) => {
    if(cp.length >= 4 ){
      axios({
        method: 'post',
        url: URL_ZIPCODE,
        data: {code: cp},
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'application/json',
        },
      }).then(function (response) {
        if(response.data.data.length > 0){
          setShowMessageCPInvoice(false);
          if(response.data.data.length === 1){
            setPaymentMethod({
              ...paymentMethod,
              municipalityinvoice: response.data.data[0].mnpio,
              stateinvoice: response.data.data[0].estado,
              suburbinvoice: response.data.data[0].asenta
            });
          }else{
            setPaymentMethod({
              ...paymentMethod,
              municipalityinvoice: response.data.data[0].mnpio,
              stateinvoice: response.data.data[0].estado,
              suburbinvoice: response.data.data[0].asenta
            });
          }
        }else{
          setShowMessageCPInvoice(true);
          setPaymentMethod({
            ...paymentMethod,
            municipalityinvoice: '',
            stateinvoice: '',
            suburbinvoice: ''
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }

  useEffect(() => {
    //revisar aqui
    if ( stateAux ) {
      console.log('useEffect -> stateAux ->', stateAux);
      setPaymentMethod({
        ...paymentMethod,
        name: stateAux.name ? stateAux.name : '',
        last_name: stateAux.last_name,
        second_lastname: stateAux.second_lastname,
        email: stateAux.email,
        phone: stateAux.phone,
        plan: stateAux.plan,
        price: stateAux.price
      });
      setPlan({
        ...plan,
        plan: stateAux.plan,
        title: stateAux.title,
        price: stateAux.price,
        info1: stateAux.info1,
        info2: stateAux.info2,
        info3: stateAux.info3,
        services: stateAux.services,
      });
      setTotal(stateAux.price);
      
    }
  }, [stateAux, setPaymentMethod]);

  useEffect(() => {
    const isBrowser = typeof window;
    if (isBrowser) {
      const ancho = window.innerWidth <= 1023 ? 1 : 0;
      setScreenBtnShowPlan(ancho);
      // console.log('ancho ->', ancho);
    }
  }, [width]);

  useEffect(() => {
    //onValidateInvoice();
    onValidateData();
  }, [invoice]);

  useEffect(() => {
    onGetStorage();
  }, []);

  useEffect(() => {
    if ( save > 0 ) {
      const r = onConfigSaveStoragePersonalData();
      if ( r ) {
        onSaveStoragePersonalData();
      }
    }
  }, [save]);

  useEffect(() => {
    onGetStoragePersonalData();
  }, []);

  useEffect(() => {
    onSaveStorage();
  }, []);

  useEffect(()=>{
    // console.log(paymentMethod, 'use effect paymentMethod' )
  },[paymentMethod])

  const getToken = () => {
    axios({
        method: 'post',
        url: URL_TOKEN,
        data: '{}',
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'application/json',
        },
      }).then(function (response) {
        // console.log('axios => response =>', response.data.data);
        // console.log('axios => response => data.data.checkout.id', response.data.data.checkout.id);
        // console.log('tokenConekta getToken after',tokenConekta)
        setTokenConekta(response.data.data);
        // console.log('tokenConekta getToken to',tokenConekta)
      })
      .catch(function (error) {
  
        let message = 'Ocurrió un error, intente más tarde';
        if ( error.hasOwnProperty('message') ) {
          message = error.message.toString();
          setMessageError(error.message.toString());
        }
  
        setShowMessageError(true);
  
        console.log('message =>', message);
        console.log(error);
        console.log(JSON.stringify(error));
      });
  };

  useEffect(() => {
    if(firstLoad){
      getToken()
      // console.log('tokenConekta useEffect to',tokenConekta)
    }
 }, [firstLoad]);

 useEffect(() => {
  if(iframeLoad){
    getToken()
    // console.log('tokenConekta useEffect to',tokenConekta)
  }
}, [iframeLoad])

 const iframeConekta = (conekta_token) =>{

   window.ConektaCheckoutComponents.Card({
    targetIFrame: "#conektaIframeContainer",
    //Este componente "allowTokenization" permite personalizar el tokenizador, por lo que su valor siempre será TRUE
    allowTokenization: true,
    checkoutRequestId: conekta_token.checkout.id, // // Checkout request ID, es el mismo ID generado en el paso 1
    publicKey: CONEKTA_PUBLIC_KEY, // Llaves: https://developers.conekta.com/docs/como-obtener-tus-api-keys
    options: {
      styles: {
        // inputType modifica el diseño del Web Checkout Tokenizer
        //        inputType: 'basic' // 'basic' | 'rounded' | 'line'
        inputType: 'rounded',
        // buttonType modifica el diseño de los campos de llenado de información del  Web Checkout Tokenizer
        //        buttonType: 'basic' // 'basic' | 'rounded' | 'sharp'
        buttonType: 'rounded',
        //Elemento que personaliza el borde de color de los elementos
        states: {
          empty: {
            borderColor: '#F44336' // Código de color hexadecimal para campos vacíos
          },
          invalid: {
            borderColor: '#F44336' // Código de color hexadecimal para campos inválidos
          },
          valid: {
            borderColor: '#0079c1' // Código de color hexadecimal para campos llenos y válidos
          }
        }
      },
      languaje: 'es', // 'es' Español | 'en' Ingles
      //Elemento que personaliza el botón que finzaliza el guardado y tokenización de la tarjeta
      button: {
        colorText: '#ffffff', // Código de color hexadecimal para el color de las palabrás en el botón de: Alta de Tarjeta | Add Card
        text: 'Validar', //Nombre de la acción en el botón ***Se puede personalizar
        backgroundColor: '#FF8675!important' // Código de color hexadecimal para el color del botón de: Alta de Tarjeta | Add Card
      },
      //Elemento que personaliza el diseño del iframe
      iframe: {
        colorText: '#5B52BC',  // Código de color hexadecimal para el color de la letra de todos los campos a llenar
        backgroundColor: '#FFFFFF' // Código de color hexadecimal para el fondo del iframe, generalmente es blanco.
      }
    },
    onCreateTokenSucceeded: function(token) {
      
      console.log(token, 'onCreateTokenSucceeded token')
      let requireinvoiceInput = '';
      requireinvoiceInput = document.getElementsByName('requireinvoice')[0].value;
      let municipioInput = '';
      let cpInput = '';
      let estadoInput = '';
      let suburbInput = '';
    
      if(requireinvoiceInput === 'false'){
        municipioInput = document.getElementsByName('municipality')[0].value;
        cpInput = document.getElementsByName('cp')[0].value;
        suburbInput = document.getElementsByName('suburb')[0].value;
        estadoInput = document.getElementsByName('state')[0].value;
      }else{
        municipioInput = document.getElementsByName('municipalityinvoice')[0].value;
        suburbInput = document.getElementsByName('suburbinvoice')[0].value;
        cpInput = document.getElementsByName('cpinvoice')[0].value;
        estadoInput = document.getElementsByName('stateinvoice')[0].value;
      }

      
      const data = {
        name:                 paymentMethod.name,
        last_name:            paymentMethod.last_name,
        second_lastname:      paymentMethod.second_lastname,
        email:                paymentMethod.email,
        phone:                paymentMethod.phone,
        methodPay:            'TARJETA',
        plan:                 paymentMethod.plan,
        price:                paymentMethod.price,
        diary:                'none',
        additional_service_1: '0',
        additional_service_2: '0',
        discount:             paymentMethod.promotionalCode || '',
        discountColaborador:  paymentMethod.promotionalCodeColaborador || '',
        has_invoice:          (requireinvoiceInput === 'false') ? '0' : '1',
        type_of_person:       invoice.typeperson,
        rfc:                  invoice.rfc,
        company_name:         invoice.businessname,
        zip_code:             cpInput,
        street:               paymentMethod.street,
        outdoor_number:       paymentMethod.externalnumber,
        interior_number:      paymentMethod.internalnumber,
        suburb:               suburbInput,
        municipality:         municipioInput,
        state:                estadoInput,
        promocod_colaborador: discountPromo.promocod_colaborador,
        promocod_customer:    discountPromo.promocod_customer,
        discountQty:          discountPromo.discountQty,
        regimen:              invoice.regimen,
        token:                token,
      };

      console.log('data', {data});
      onSendPay(data);
                  
    },
    onCreateTokenError: function(error) {

      console.log(tokenConekta, 'onCreateTokenError tokenConekta');
      setIsOpen(false);
      getToken()
      console.log(error)
      let divContent = document.getElementById('conektaIframeContainer')
      divContent.style.background = "#FFF!important";
      // document.getElementsByClassName('IframeTokenizer').style.padding = "10px";
      console.log(divContent,'divContent')
      while (divContent.hasChildNodes()) {
        divContent.removeChild(divContent.firstChild);
      }
      let message = 'Ocurrió un error, intente más tarde';
      if ( error.hasOwnProperty('message') ) {
        message = error.message.toString();
        setMessageError(error.message.toString());
      }
      setShowMessageError(true);
    }
  })
 };

  return (
    
    <ContMetodosDePago>
      <TransitionState>
        {state => {
          // console.log('TransitionState -> state ->', state);
          if ( state.current.state.email ) {
            setStateAux(state.current.state);
            onSaveStorage(state.current.state);
          } else {
            // console.log('TransitionState - no hay datos');
          }
        }}
      </TransitionState>
      <h1></h1>
      <Container isFluid={true}>
        {firstLoad ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={validate}
          onSubmit={async (values, {resetForm}) => {
            console.log('onSubmit');
            // alert('onSubmit');
            await onSubmit(values);
            // resetForm();
          }}
        >
          
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldTouched,
              setFieldValue,
            } = props;
            // console.log('values =>', values);
            // console.log('handleChange =>', handleChange);

            return (
              <Form>
                <Columns className={'full-height columns-reorder-mobile'} isMultiline={true}>
                  <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 2, widescreen: 2}}>
                    &nbsp;
                  </Column>
                  <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}}>
                    {invoice.requireinvoice ? (
                      <ContColumnInner>
                        <TitleSectionMini>Facturación</TitleSectionMini>
                        <PieTitleSectionMini>Ingresa tus datos de facturación.</PieTitleSectionMini>
                        <Linea/>
                        <ContForm>
                          <ContField>
                            <SelectMySMaterial
                              name="typeperson"
                              label="Persona física / moral"
                              data={dataTypePerson}
                              onChange={(e) => {
                                onChangeTypePerson(e.target.value);
                              }}
                            />
                          </ContField>
                          <ContField>
                            <InputTextMySMaterial
                              name='rfc'
                              label='RFC con homoclave'
                              value={values.rfc}
                              maxLength={13}
                              // onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e);
                                onChangeRFC(e.target.value);
                              }}
                              setState={setPaymentMethod}
                              state={paymentMethod}
                            />
                            {showMessageRFC?<ErrorMessage>RFC No válido</ErrorMessage>:<h1></h1>}
                          </ContField>
                          <ContField>
                            <InputTextMySMaterial
                              name='businessname'
                              label='Razón social'
                              value={values.businessname}
                              // onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e);
                                onChangeBussinesName(e.target.value);
                              }}
                              setState={setPaymentMethod}
                              state={paymentMethod}
                            />
                          </ContField>
                          <ContField>
                            <SelectMySMaterial
                              name="regimenFiscal"
                              label="Régimen Fiscal"
                              data={dataRegimenFiscal()}
                              onChange={(e) => {
                                onChangeRegimen(e.target.value);
                              }}
                            />
                          </ContField>
                          <ContField>
                            <CheckboxMySMaterial
                              name={'samebillingaddress'}
                              label={'Utilizar la misma dirección de facturación que la tarjeta'}
                              value={values.samebillingaddress}
                              onChange={(e) => {
                                // console.log('e.target.value =>', e.target.value);
                                // console.log('e.target.value => typeof  =>', typeof e.target.value);
                                if (e.target.value === 'true') {
                                  console.log('samebillingaddress => SIN DATOS');
                                  setFieldValue('streetinvoice', '');
                                  setFieldValue('externalnumberinvoice', '');
                                  setFieldValue('internalnumberinvoice', '');
                                  setFieldValue('cpinvoice', '');
                                  setFieldValue('municipalityinvoice', '');
                                  setFieldValue('stateinvoice', '');
                                  setFieldValue('suburbinvoice', '');

                                  setInvoice({
                                    ...invoice,
                                    samebillingaddress: false,
                                    street:             '',
                                    externalnumber:     '',
                                    internalnumber:     '',
                                    cp:                 '',
                                    municipality:       '',
                                    state:              '',
                                    suburb: '',
                                  });
                                  setPaymentMethod({
                                    ...paymentMethod,
                                    suburbinvoice: '',
                                    municipalityinvoice: '',
                                    stateinvoice: ''
                                  })
                                } else {
                                  console.log('samebillingaddress => CON DATOS');
                                  setFieldValue('streetinvoice', paymentMethod.street);
                                  setFieldValue('externalnumberinvoice', paymentMethod.externalnumber);
                                  setFieldValue('internalnumberinvoice', paymentMethod.internalnumber);
                                  setFieldValue('cpinvoice', paymentMethod.cp);
                                  setFieldValue('suburbinvoice', paymentMethod.suburb);
                                  setFieldValue('municipalityinvoice', paymentMethod.municipality);
                                  setFieldValue('stateinvoice', paymentMethod.state);

                                  setInvoice({
                                    ...invoice,
                                    samebillingaddress: true,
                                    street:             paymentMethod.street,
                                    externalnumber:     paymentMethod.externalnumber,
                                    internalnumber:     paymentMethod.internalnumber,
                                    cp:                 paymentMethod.cp,
                                    municipality:       paymentMethod.municipality,
                                    state:              paymentMethod.state,
                                    suburb:             paymentMethod.suburb
                                  });

                                  setPaymentMethod({
                                    ...paymentMethod,
                                    streetinvoice:             paymentMethod.street,
                                    externalnumberinvoice:     paymentMethod.externalnumber,
                                    internalnumberinvoice:     paymentMethod.internalnumber,
                                    cpinvoice:                 paymentMethod.cp,
                                    municipalityinvoiceity:    paymentMethod.municipality,
                                    stateinvoice:              paymentMethod.state,
                                    suburbinvoice:             paymentMethod.suburb
                                  });
                                  console.log(invoice);
                                }
                                console.log(invoice);
                                handleChange(e);
                                // triggerSaveLocalStorage();
                                onSameBillingAddress(e.target.value);
                              }}
                              setState={setInvoice}
                              state={invoice}
                            />
                          </ContField>

                          <ContField>
                            <InputTextMySMaterial
                              name='streetinvoice'
                              label='Calle'
                              value={values.streetinvoice}
                              onChange={(e) => {
                                handleChange(e);
                                onChangeStreetInvoice(e.target.value);
                              }}
                              setState={setPaymentMethod}
                              state={paymentMethod}
                            />
                          </ContField>
                          <ContColumnsInLine>
                            <ContField>
                              <InputTextMySMaterial
                                name='externalnumberinvoice'
                                label='Nª Exterior'
                                value={values.externalnumberinvoice}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  handleChange(e);
                                  onChangeNumExtInvoice(e.target.value);
                                }}
                                setState={setPaymentMethod}
                                state={paymentMethod}
                              />
                            </ContField>
                            <Separador/>
                            <ContField>
                              <InputTextMySMaterial
                                name='internalnumberinvoice'
                                label='Nª Interior'
                                value={values.internalnumberinvoice}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  handleChange(e);
                                  onChangeNumIntInvoice(e.target.value);
                                }}
                                setState={setPaymentMethod}
                                state={paymentMethod}
                              />
                            </ContField>
                          </ContColumnsInLine>
                          <ContField>
                            <InputTextMySMaterial
                              type="number"
                              name='cpinvoice'
                              label='CP'
                              value={values.cpinvoice}
                              maxLength={5}
                              // onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e);
                                onChangeCPInvoice(e.target.value);
                              }}
                              setState={setPaymentMethod}
                              state={paymentMethod}
                            />
                            {showMessageCPInvoice?<ErrorMessage>No se encontró el Código Postal</ErrorMessage>:<h1></h1>}
                          </ContField>
                          <ContField>
                            <InputTextMySMaterial
                              name='suburbinvoice'
                              label='Colonia'
                              value={values.suburbinvoice || paymentMethod.suburbinvoice}
                              // onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e);
                                onChangeStateInvoice(e.target.value);
                              }}
                              setState={setPaymentMethod}
                              state={paymentMethod}
                            />
                          </ContField>
                          <ContField>
                            <InputTextMySMaterial
                              name='municipalityinvoice'
                              label='Alcaldía / Municipio'
                              value={values.municipalityinvoice || paymentMethod.municipalityinvoice}
                              // onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e);
                                onChangeMunicipalityInvoice(e.target.value);
                              }}
                              setState={setPaymentMethod}
                              state={paymentMethod}
                            />
                          </ContField>
                          <ContField>
                            <InputTextMySMaterial
                              name='stateinvoice'
                              label='Estado'
                              value={values.stateinvoice || paymentMethod.stateinvoice}
                              // onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e);
                                onChangeStateInvoice(e.target.value);
                              }}
                              setState={setPaymentMethod}
                              state={paymentMethod}
                            />
                          </ContField>
                        </ContForm>
                      </ContColumnInner>
                    ) : (
                      <ContColumnInner>
                        <TitleSectionMini>Método <span>de pago</span></TitleSectionMini>
                        <PieTitleSectionMini>Ingresa tus datos bancarios.</PieTitleSectionMini>
                        <Linea/>

                        <ContField>
                          <ContIconCard>
                            <img src={imgCardPayment} alt={'imgCardPayment'}/>
                            {/* <p style={{textAlign: 'center'}}>Pago Seguro</p> */}
                          </ContIconCard>
                          {/*<InputTextMySMaterial
                            name="methodPay"
                            label="Método de pago"
                            //data={dataMethodPay}
                            onChange={(e) => {
                              handleChange(e);
                              onValidatePersonalData(values);
                              onChangeMethodPay(e.target.value);
                            }}
                            value={values.methodPay}
                            setState={setPaymentMethod}
                            disabled={true}
                            state={paymentMethod}
                          />*/}
                        </ContField>
                        <Separador/>
                        <ContForm>
                          {/*<ContField>
                            {JSON.stringify(paymentMethod)}
                          </ContField>*/}
                          <TitleSectionSecondary>
                            Dirección de la tarjeta
                          </TitleSectionSecondary>
                          <ContField>
                            <InputTextMySMaterial
                              name='street'
                              label='Calle'
                              value={values.street}
                              // onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e);
                                onValidatePersonalData(values);
                                onChangeCardStreet(e.target.value);
                              }}
                              setState={setPaymentMethod}
                              state={paymentMethod}
                            />
                          </ContField>
                          <ContColumnsInLine>
                            <ContField>
                              <InputTextMySMaterial
                                name='externalnumber'
                                label='Nª Exterior'
                                value={values.externalnumber}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  handleChange(e);
                                  onValidatePersonalData(values);
                                  onChangeCardExternalNumber(e.target.value);
                                }}
                                setState={setPaymentMethod}
                                state={paymentMethod}
                              />
                            </ContField>
                            <Separador/>
                            <ContField>
                              <InputTextMySMaterial
                                name='internalnumber'
                                label='Nª Interior'
                                value={values.internalnumber}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  handleChange(e);
                                  onChangeCardInternalNumber(e.target.value);
                                }}
                                setState={setPaymentMethod}
                                state={paymentMethod}
                              />
                            </ContField>
                          </ContColumnsInLine>
                          <ContField>
                            <InputTextMySMaterial
                              type="number"
                              name='cp'
                              label='CP'
                              value={values.cp}
                              maxLength={5}
                              // onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e);
                                onValidatePersonalData(values);
                                onChangeCP(e.target.value);
                              }}
                              setState={setPaymentMethod}
                              state={paymentMethod}
                            />
                          </ContField>
                          {showMessageCP?<ErrorMessage>No se encontró el Código Postal</ErrorMessage>:<h1></h1>}
                          <ContField>
                            <InputTextMySMaterial
                              name='suburb'
                              label='Colonia'
                              value={values.suburb || paymentMethod.suburb}
                              // onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e);
                                onValidatePersonalData({...values, municipality: paymentMethod.suburb});
                                onChangeMunicipality(e.target.value);
                              }}
                              setState={setPaymentMethod}
                              state={paymentMethod}
                            />
                          </ContField>
                          <ContField>
                            <InputTextMySMaterial
                              name='municipality'
                              label='Alcaldía / Municipio'
                              value={values.municipality || paymentMethod.municipality}
                              // onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e);
                                onValidatePersonalData({...values, municipality: paymentMethod.municipality});
                                onChangeMunicipality(e.target.value);
                              }}
                              setState={setPaymentMethod}
                              state={paymentMethod}
                            />
                          </ContField>
                          <ContField>
                            <InputTextMySMaterial
                              name='state'
                              label='Estado'
                              value={values.state || paymentMethod.state}
                              // onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e);
                                onValidatePersonalData(values);
                                onChangeState(e.target.value);
                              }}
                              setState={setPaymentMethod}
                              state={paymentMethod}
                            />
                          </ContField>
                        </ContForm>
                      </ContColumnInner>
                    )}
                    <ContColumnInner>
                      <ContForm>
                        <ContImportantElement>
                          <CheckboxMySMaterial
                            name={'requireinvoice'}
                            label={'Requiero factura'}
                            // value={values.requireinvoice}
                            value={invoice.requireinvoice}
                            disable={!invoice.requireinvoiceDisable}
                            // onChange={handleChange}
                            onChange={(e) => {
                              onValidatePersonalDataForInvoice(values, paymentMethod);
                              handleChange(e);
                            }}
                            setState={setInvoice}
                            state={invoice}
                            fontColor={GlobalColors.colorPrimary}
                          />
                        </ContImportantElement>
                      </ContForm>
                    </ContColumnInner>
                  </Column>
                  <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}}>
                    <ContColumnInner>
                      <TitleSectionMiniMobile>Resumen <span>del plan</span></TitleSectionMiniMobile>
                      <PieTitleSectionMiniMobile>Revisa el detalle de tu plan.</PieTitleSectionMiniMobile>
                      {screenBtnShowPlan
                        ? <ContBtnShowPlanMobile type='button' onClick={() => onShowPlan()}>
                          <img src={showPlan ? IconUp : IconDown} alt={'showPlan'}/>
                        </ContBtnShowPlanMobile>
                        : null
                      }
                      <Linea/>

                      <ContForm className={showPlan ? '' : 'no-display-mobile'}>
                        <ContProduct>
                          <ContProductItemLeft>
                            <TitlePlanSummary>Producto</TitlePlanSummary>
                            <NamePlanSummary>{plan.title}</NamePlanSummary>
                          </ContProductItemLeft>
                          <ContProductItemRight>
                            <AniLink
                              to={`/client/?token=${token}`}
                              exit={{
                                length: 0.5, state: {
                                  auxToken: 1,
                                  name: paymentMethod.name,
                                  last_name: paymentMethod.last_name,
                                  second_lastname: paymentMethod.second_lastname,
                                  email: paymentMethod.email,
                                  phone: paymentMethod.phone,
                                },
                              }}
                              entry={{
                                delay: 0.5, state: {
                                  auxToken: 1,
                                  name: paymentMethod.name,
                                  last_name: paymentMethod.last_name,
                                  second_lastname: paymentMethod.second_lastname,
                                  email: paymentMethod.email,
                                  phone: paymentMethod.phone,
                                },
                              }}
                              state={{
                                auxToken: 1,
                                name: paymentMethod.name,
                                last_name: paymentMethod.last_name,
                                second_lastname: paymentMethod.second_lastname,
                                email: paymentMethod.email,
                                phone: paymentMethod.phone,
                              }}
                            >
                              <img src={IconEdit} alt={''}/>
                            </AniLink>
                          </ContProductItemRight>
                        </ContProduct>
                        <Linea/>
                        <ContServices>
                          <TitlePlanSummary>Servicios</TitlePlanSummary>
                          {plan.services && (
                            <ContServicesDetail>
                              {plan.services.map((item, index) => {
                                const icon = index === 0 ? IconPhone : index === 1 ? IconBell : IconPlus;
                                return (
                                  <ContServicesDetailItem key={index}>
                                    <ContServicesDetailItemIco>
                                      <img src={icon} alt={''}/>
                                    </ContServicesDetailItemIco>
                                    <ContServicesDetailItemInfo>{item.label}</ContServicesDetailItemInfo>
                                  </ContServicesDetailItem>
                                );
                              })}
                            </ContServicesDetail>
                          )}
                        </ContServices>
                        <Linea/>
                        <ContTotal>
                          <TitlePlanSummary>Total</TitlePlanSummary>
                          <ContTotalDetail>
                            <ContTotalItem>
                              <ContTotalNumber>${total}<span>/Mes</span></ContTotalNumber>
                              <ContTotalTextIVA>(IVA Incluido)</ContTotalTextIVA>
                            </ContTotalItem>
                            {discountPromo.showDisccount?
                            <>
                            <ContTotalItem>
                              <ContTotalNumber>{discountPromo.discountPorcentaje}<span>%</span></ContTotalNumber>
                              <ContTotalTextIVA>(Descuento)</ContTotalTextIVA>
                            </ContTotalItem>
                            <Linea/>
                            <ContTotalItem>
                              <ContTotalNumber>${discountPromo.discountTotal}<span></span></ContTotalNumber>
                            </ContTotalItem>
                            </>
                            :
                            <>
                            </>
                            }
                            
                          </ContTotalDetail>
                        </ContTotal>
                        <Linea/>
                        <ContProduct>
                          <ContProductItemLeft>
                            <ContField>
                              <InputTextMySMaterial
                                name='promotionalCode'
                                label='Introduce tu código promocional'
                                value={values.promotionalCode}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  handleChange(e);
                                  onChangePromotionalCode(e.target.value);
                                }}
                                setState={setPaymentMethod}
                                state={paymentMethod}
                                fontColor={GlobalColors.colorPrimary}
                                borderColor={GlobalColors.colorPrimary}
                                backgroundColor={GlobalColors.colorMenuFooterPrimary}
                              />
                            </ContField>
                            {/*<ContField>
                              <InputTextMySMaterial
                                name='promotionalCodeColaborador'
                                label='Introduce tu código P. de colaborador'
                                value={values.promotionalCodeColaborador}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  handleChange(e);
                                  onChangePromotionalCodeColaborador(e.target.value);
                                }}
                                setState={setPaymentMethod}
                                state={paymentMethod}
                                fontColor={GlobalColors.colorPrimary}
                                borderColor={GlobalColors.colorPrimary}
                                backgroundColor={GlobalColors.colorMenuFooterPrimary}
                              />
                            </ContField>
                            */}
                          </ContProductItemLeft>
                          <ContProductItemRight>
                            <button type='button' onClick={aplicarPromo} style={{width: '100%', backgroundColor: '#FF8675', cursor: 'pointer', color: '#FFFFFF', textAlign: 'center', borderRadius: '50px', padding: '10px', marginLeft: '10px'}}>
                              Aplicar
                            </button>
                            
                          </ContProductItemRight>
                        </ContProduct>
                      </ContForm>
                    </ContColumnInner>
                  </Column>
                  <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 2, widescreen: 2}}>
                    &nbsp;
                  </Column>
                </Columns>
                <Columns className={'full-height columns-reorder-mobile'} isMultiline={true}>
                  <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 2, widescreen: 2}}>
                    &nbsp;
                  </Column>
                  <Column className={'center-column'} style={{alignContent: 'center'}} isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}}>             
                  <div style={{alignContent: 'center'}}>
                   <ContIcon><img src={imgPayment} style={{textAlign: 'center'}} alt={'imgPayment'}/></ContIcon>
                  </div>
                  </Column>
                  <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}}>
                    <ContColumnInner>
                    <p style={{width: '100%', textAlign: 'center', padding: '10px', marginTop: 0, marginLeft: '10px', fontSize: '15px', fontWeight: 'bold' }}>En el próximo paso le pediremos información de su tarjeta. Se realizará inicialmente un cargo de 3 pesos para validar su tarjeta. Muchas gracias por su confianza</p>
                    </ContColumnInner>
                    <ContColumnInner>
                      {(values.methodPay === 'TARJETA')?
                      <BtnPrimary type={'button'} onClick={openModal}>
                        Ya estas a un paso
                      </BtnPrimary>
                      :
                      <BtnPrimary type={'submit'}>
                        Ya estas a un paso
                      </BtnPrimary>
                      }
                    </ContColumnInner>
                  </Column>
                  <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 2, widescreen: 2}}>
                    &nbsp;
                  </Column>
                </Columns>
              </Form>
            );
          }}
        </Formik>
        ) : null
        }
      </Container>

      
      <Modal 
        isActive={modalIsOpen}>
        <ModalBackground/>
        <ModalContent>
          <div id="conektaIframeContainer" style={{height: '1350px', marginBottom:0}}></div>
        </ModalContent>
        <ModalClose onClick={closeModal}/>
      </Modal>
      

      <Modal isActive={showMessage}>
        <ModalBackground/>
        <ModalContent>
          <ContMessage id="msg-content">
            {showMessageError
              ? <MessageCompError error={messageError}/>
              : <MessageComp/>
            }
          </ContMessage>
        </ModalContent>
        <ModalClose onClick={() => handleCloseMessage()}/>
        <a id={'go-welcome'} href={redirect}/>
        <AniLink
          id={'goto-welcome'}
          to={'/welcome'}
          exit={{
            length: 0.5, state: {
              redirect: redirect,
              name: paymentMethod.name,
              charge: charge
            },
          }}
          entry={{
            delay: 0.5, state: {
              redirect: redirect,
              name: paymentMethod.name,
              charge: charge
            },
          }}
          state={{
            redirect: redirect,
            name: paymentMethod.name,
            charge: charge
          }}
        />
      </Modal>
    </ContMetodosDePago>
  );
};
