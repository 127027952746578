import React, {useEffect} from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import {StylesCheckBox} from './styles';

const CheckboxMySMaterial = ({
    index,
    name,
    nameParent,
    label,
    value,
    disable = false,
    simpleCheck = true,
    onChange,
    setState,
    state = {},
    ...otherProps
  }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  // console.log(`[${index}]value =>`, value);
  // console.log('field =>', field);
  // console.log('meta =>', meta);

  useEffect(() => {
    // console.log(`##########################`);
    // console.log(`##########################`);
    // console.log(`##########################`);
    // console.log(`useEffect =>`, value);
    setFieldValue(name, value);
  }, []);

  const handleChange = evt => {
    const { checked } = evt.target;
    setFieldValue(name, checked);
  };

  const configCheckbox = {
    // value: value,
    checked: value,
    disable: disable,
    ...field,
    // ...otherProps,
    // onChange: handleChange,
    onChange: (e) => {
      // console.log('configCheckbox =>', e.target.value);
      // console.log('configCheckbox => type => ', typeof e.target.value);
      // console.log('configCheckbox => simpleCheck =>', simpleCheck);
      if (simpleCheck) {
        // console.log('configCheckbox => state =>', state);
        // console.log('configCheckbox => name =>', name);
        setState({
          ...state,
          [name]: e.target.value !== 'true'
        });
        handleChange(e);
        onChange(e);
      } else {
        // let arStates = {label: label, value: e.target.value !== 'true'};
        let arStates = state[nameParent];
        arStates[index].value = e.target.value !== 'true';

        // console.log('configCheckbox => state =>', state);
        // console.log('configCheckbox => name =>', name);
        // console.log('configCheckbox => arStates =>', arStates);

        setState({
          ...state,
          [nameParent]: arStates
        });
        handleChange(e);
        onChange(e);
      }
    },
  };

  const configFormControl = {};
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
  }

  return (
    <FormControl
      {...configFormControl}
      style={{marginLeft: '15px'}}
    >
      <StylesCheckBox/>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox {...configCheckbox} />}
          label={label}
        />
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxMySMaterial;
