import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import {GlobalStylesInputTextMySMaterial} from './styles';
import {GlobalColors, GlobalColorsInputText} from '../../../styles/appColors';

const styles = theme => ({
  cssLabel: {
    color : GlobalColorsInputText.label
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      backgroundColor: `${GlobalColorsInputText.primaryBg} !important`,
      borderColor: `${GlobalColorsInputText.labelFocus} !important`,
    }
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '2px',
    borderRadius: '10px',
    borderColor: `${GlobalColorsInputText.labelFocus} !important`,
  },
});

const InputTextMySMaterial = ({
    type = 'text',
    name,
    onChange,
    value,
    backgroundColor = GlobalColorsInputText.primaryBg,
    borderColor = GlobalColorsInputText.labelFocus,
    fontColor = GlobalColors.text,
    maxLength = 2000,
    setState,
    state = {},
    ...otherProps
  }) => {
  const [field, meta] = useField(name);
  const { classes } = otherProps;

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'outlined',
    value: value,
    onChange: (e) => {
      if (type === 'number') {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
          // console.log('e.target.value =>', true);
          // value = e.target.value;
          setState({
            ...state,
            [name]: e.target.value
          });
          onChange(e);
        }
      } else {
        // console.log(e.target.value);
        setState({
          ...state,
          [name]: e.target.value
        });
        onChange(e);
      }
    },
    style: {
      backgroundColor: backgroundColor,
      color: fontColor,
    },
    InputLabelProps: {
      classes: {
        root: classes.cssLabel,
        focused: classes.cssFocused,
      },
    },
    InputProps: {
      classes: {
        root: classes.cssOutlinedInput,
        // focused: classes.cssFocused,
        notchedOutline: classes.notchedOutline,
      },
    }
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  return (
    <div>
      <GlobalStylesInputTextMySMaterial/>
      <TextField
        inputProps={{
          maxlength: maxLength
        }}
        {...configTextfield}
      />
    </div>
  );
};

// export default InputTextMySMaterial;
export default withStyles(styles)(InputTextMySMaterial);

